<template>
  <ui-component-modal
    modalTitle="Create all invoices"
    :isSaving="isCreating"
    :isSavingSuccess="isCreatingSuccess"
    :isSavingError="isCreatingError"
    :hideFooterButtons="isCreatingError || isCreatingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="CreateAllInvoices"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="has-margin-bottom is-size-5 has-text-centered">
        <div>
          Are you sure you want to create all the invoices for
          {{ invoicesPageState.month | getMonthName }}
          {{ invoicesPageState.year }}?
        </div>
        <div>
          {{ invoicesPageState.noInvoiceLocations.length }} invoices will be
          created.
        </div>
      </div>
      <div class="has-text-centered">
        <BaseCheckbox
          v-model="isCreateConfirmed"
          class="has-text-left"
          :label="'Yes, I am sure!'"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import reservationProvider from '@/providers/reservation'

const BaseCheckbox = () => import('@/components/UI/Form/BaseCheckbox')

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,

      locationId: {
        type: Number,
        default: 0,
      },

      month: {
        type: Number,
        default: 0,
      },

      year: {
        type: Number,
        default: 0,
      },
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isCreating: false,
      isCreatingSuccess: false,
      isCreatingError: false,
      isCreateConfirmed: false,
    }
  },

  computed: {
    ...mapState('feeinvoiceStore', ['invoicesPageState']),
  },

  created() {},

  methods: {
    CreateAllInvoices() {
      this.$emit('createAllConfirmed')

      setTimeout(() => {
        this.onClickCancel()
      }, 1500)
    },
  },
}
</script>
